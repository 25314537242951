@import "../../global.scss";

.intro{
    background-color: white;
    display: flex;

    @include mobile{
        flex-direction: column;

    }

    .left{
        flex: 0.5;
        // background-image: url("/bg1.webp");
        // border-radius: 4000px;
        .imgContainer{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            img{
                height: 100%;
            }
        }

    }
    .right{
        flex: 0.5;
        background-color: white;
        position: relative;
        .wrapper{
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1{
                font-size: 45px;
                margin: 10px 0;
            }

            h2{
                font-size: 35px;
            }

            h3{
                font-size: 30px;
                color: crimson;


            }
            span{
                font-size: inherit;
            }

            .ityped-cursor{
                animation: blink 1s infinite;
                
            }
        }

        a{
            position: absolute;
            bottom: 10px;
            left: 45%;
            size-adjust: 60px;

            animation: arrowBlink 2s infinite;
        }
        @keyframes arrowBlink {
            100%{
                opacity: 0;
            }
            
        }
    }
}